import Vue from 'vue'
import VueRouter from 'vue-router'
import CollectionsAlbum from '../views/CollectionsAlbum.vue'
import CardView from '../views/CardView.vue'
import CardShow from '../views/CardShow.vue'
import CardMaker from '../views/CardMaker.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
  },
  {
    path: '/album',
    name: 'album',
    component: CollectionsAlbum
  },
  {
    path: '/view',
    name: 'view',
    component: CardView,
    props: true
  },
  {
    path: '/show',
    name: 'show',
    component: CardShow,
    props: true
  },
  {
    path: '/maker',
    name: 'maker',
    component: CardMaker,
  }
]

const router = new VueRouter({
  routes
})

export default router
