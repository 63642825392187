import Vue from 'vue'
import Vuex from 'vuex'
import PhoneAPI from './../PhoneAPI'

Vue.use(Vuex)

const state = {
  collections: {},
  ownedCards: {},
  cardsFetched: false
}

const getters = {
  collections: ({ collections }) => collections,
  ownedCards: ({ ownedCards }) => ownedCards
}

const mutations = {
  SET_OWNED_CARDS (state, ownedCards) {
    state.ownedCards = ownedCards
    state.cardsFetched = true
  },
}

const actions = {
  fetchOwnedCards ({ state }) {
    if (state.cardsFetched === true) return
    PhoneAPI.fetchOwnedCards()
  },
}

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations
})

state.collections = PhoneAPI.loadCollections()

if (process.env.NODE_ENV !== 'production') {
  state.ownedCards = {
    kittyclaw: {
        saki: 4,
        princesse: 2,
        muffy: 3,
        grindy: 2,
        humpy: 2,
        smokey: 1,
        poopy: 5,
        master: 2,
        wasabi: 4,
        kittyclaw: 1,
    },
    secretstory: {
      nina: 1,
      villa: 1,
      beverly: 1,
      hassan: 1,
      hellza: 1,
      ines: 1,
      jayden: 1,
      maddy: 1,
      mya: 1,
      pablo: 1,
      patrick:1,
      voix: 1,
      weazel: 1
  }
  }
}
