<template>
  <div class="tab-container">
    <div class="tab-content" :class="{ active: active === true, noHover: disabled === true }" :style="containerStyle">
      <img :src="image">
      <div class="hide" v-show="disabled === true"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CollectionTab',
  props: {
    active: Boolean,
    disabled: Boolean,
    image: String
  },
  computed: {
    containerStyle() {
      return {
        border: Math.floor(document.documentElement.clientHeight * 0.004) + 'px solid black',
      }
    }
  }
}
</script>


<style scoped>

.tab-container {
  display: flex;
  align-items: flex-end;
}

.tab-content {
  position: relative;
  border-top-left-radius: 10%;
  border-top-right-radius: 10%;
  border-bottom: 0px solid !important;
  margin-right: 2px;
  font-size: 1vh;
  height: 6vh;
  width: auto;
  display: flex;
  align-items: center;
}

.tab-content img {
  height: 100%
}

.tab-content:hover {
  cursor: pointer;
  height: 7vh;
}

.tab-content.noHover{
    pointer-events: none;
}

.tab-content.active{
  height: 8vh;
}

.hide {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

</style>
