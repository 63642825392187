import Vue from 'vue'
import CardView from './views/CardView'

export default {
  ViewCard (propsData = {}) {
    return new Promise((resolve) => {
      propsData['preview'] = false
      let card = new (Vue.extend(CardView))({
        el: document.createElement('div'),
        propsData
      })
      document.querySelector('#app').appendChild(card.$el)
      card.$on('submit', (data) => {
        resolve(data)
        card.$el.parentNode.removeChild(card.$el)
        card.$destroy()
      })
      card.$on('cancel', () => {
        resolve()
        card.$el.parentNode.removeChild(card.$el)
        card.$destroy()
      })
    })
  }
}
