import router from './router'
// import Vue from 'vue'
import store from '@/store'

const BASE_URL = 'http://bay_cards/'

class PhoneAPI {
  constructor () {
    window.addEventListener('message', (event) => {
      const eventType = event.data.event
      if (eventType !== undefined && typeof this['on' + eventType] === 'function') {
        this['on' + eventType](event.data)
      }
    })
  }

  loadCollections () {
    return require('../public/collections.json')
  }

  async post (method, data) {
    const ndata = data === undefined ? '{}' : JSON.stringify(data)
    const response = await window.jQuery.post(BASE_URL + method, ndata)
    return JSON.parse(response)
  }

  fetchOwnedCards () {
    this.post('onFetchOwnedCards')
  }

  onviewAlbum () {
    router.push({ name: 'album' })
  }

  onviewCard (data) {
    router.push({ name: 'view', params: { cardKey: data.card, collectionKey: data.collection } })
  }

  onshowCard (data) {
    router.push({ name: 'show', params: { cardKey: data.card, collectionKey: data.collection } })
  }

  onsetOwnedCards (data) {
    store.commit('SET_OWNED_CARDS', data.ownedCards)
  }

  onhide () {
    router.push({ name: 'home' })
  }

  exitAlbumView () {
    this.post('onExitAlbumView')
  }

  exitCardView () {
    this.post('onExitCardView')
  }

  addRemoveCardFromAlbum (collection, card, inAlbum) {
    this.post('onAddRemoveCardFromAlbum', {collection, card, inAlbum})
  }

  showCard (collection, card) {
    this.post('onShowCard', {collection, card})
  }

}

const instance = new PhoneAPI()
export default instance
