<template>
  <div class="collection-container">
    <div class="collection-header">
      <div class="collection-tab-container" :key="key" v-for="(collection, key) in nonEmptyCollections" @click="onCollectionClicked(collection)">
        <CollectionTab :image="collection.icon" :active="activeCollection === collection" :disabled="inPreview === true"/>
      </div>
    </div>
    <div ref="album" class="collection-content">
      <div class="hide" v-show="inPreview === true" :style="hideHeight()"></div>
      <div class="collection-card-container" :key="key" v-for="(card, key, index) in activeCollection.cards" @click="onCardClicked(activeCollection, card)">
        <CollectionCard class="collection-card" :style="{boxShadow: card.borderColor + ' 0px 0.75vh 2.25vh'}" :class="{greyed: cardCount(card, activeCollection) == 0 }"
          :background="card.background"
          :borderColor="card.borderColor"
          :titleColor="card.titleColor"
          :descriptionColor="card.descriptionColor"
          :statColor="card.statColor"
          :numberColor="card.numberColor"
          :title="card.title"
          :icon="card.icon"
          :icon2="card.icon2"
          :image="card.image"
          :imageBorderColor="card.imageBorderColor"
          :rarity="card.rarity"
          :description="card.description"
          :stat1Name="card.stat1Name"
          :stat1Value="card.stat1Value"
          :stat2Name="card.stat2Name"
          :stat2Value="card.stat2Value"
          :count="cardCount(card, activeCollection)"
          :number="index + 1"
          :max="Object.keys(activeCollection.cards).length"
        />
        <div class="collection-card-count">{{ cardCount(card, activeCollection) }}x</div>
      </div>
      <template v-for="n in 6 - (Object.keys(activeCollection.cards).length % 6)">
        <div v-if="Object.keys(activeCollection.cards).length % 6 !== 0" :key="n" class="collection-card-container empty"></div>
    </template>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import CollectionTab from '@/components/CollectionTab.vue'
import CollectionCard from '@/components/CollectionCard.vue'
import Modals from '../modals.js'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CollectionView',
  data: function () {
    return {
      activeCollection: undefined,
      inPreview: false
    }
  },
  components: {
    CollectionTab,
    CollectionCard
  },
  computed: {
    ...mapGetters(['collections', 'ownedCards']),
    nonEmptyCollections () {
      let nonEmpty = {}
      Object.entries(this.collections).forEach(([collectionKey, collection]) => {
        if (!collection.hideEmpty || this.isCollectionEmpty (collection) === false) {
          nonEmpty[collectionKey] = collection
        }
      })
      return nonEmpty
    },
  },
  methods: {
    ...mapActions(['fetchOwnedCards']),
    isCollectionEmpty (collection) {
      for (let card of Object.values(collection.cards)) {
        if (this.cardCount(card, collection) > 0) {
          return false
        }
      }
      return true
    },
    getCardAndCollectionKey (card, collection) {
      let collectionKey = Object.keys(this.collections).find(key => this.collections[key] == collection)
      let cardKey = Object.keys(collection.cards).find(key => collection.cards[key] == card)
      return { cardKey, collectionKey }
    },
    cardCount (card, collection) {
      let { cardKey, collectionKey } = this.getCardAndCollectionKey(card, collection)
      if (this.ownedCards[collectionKey] ===  undefined) return 0
      return this.ownedCards[collectionKey][cardKey] || 0
    },
    async onCardClicked (collection, card) {
      if (this.inPreview === true) return
      this.inPreview = true
      let { cardKey, collectionKey } = this.getCardAndCollectionKey(card, collection)
      await Modals.ViewCard({
          cardKey: cardKey,
          collectionKey: collectionKey,
          inAlbum: true
      })
      this.inPreview = false
    },
    onCollectionClicked (collection) {
      this.activeCollection = collection
    },
    hideHeight () {
      if (this.$refs.album === undefined) return
      return {
        height: this.$refs.album.scrollHeight + 'px'
      }
    },
    onKeyUp (event) {
      if (this.inPreview === false && (event.key === 'Escape' || event.key === 'Backspace')) {
        this.$phoneAPI.exitAlbumView()
      }
    },
  },
  created () {
    this.fetchOwnedCards()
    this.activeCollection = this.collections[Object.keys(this.collections)[0]]
    window.addEventListener('keyup', this.onKeyUp)
  },
  beforeDestroy () {
    window.removeEventListener('keyup', this.onKeyUp)
  }
}
</script>

<style scoped>

.collection-container {
  margin-left: 12.5%;
  margin-right: auto;
  margin-top: 8vh;
  height: 80vh;
  width: 75%;
  display: flex;
  flex-direction: column;
  user-select: none;
}

.collection-header {
  display: flex;
  justify-content: flex-start;
}

.collection-tab-container {
  display:flex;
  align-items: flex-end;
}

.collection-content {
  position: relative;
  padding-top: 3vh;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-evenly;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0.5vh 1.5vh;
  overflow:hidden;
  overflow-y: scroll;
}

.hide {
  position: absolute;
  z-index: 900;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.8);
}

.collection-content::-webkit-scrollbar {
  width: 3px;
  background-color: transparent;
}

.collection-content::-webkit-scrollbar-thumb {
  background-color: #aaaaaa;
}

.collection-card-container {
  width: 14%;
  margin-bottom: 2.5vh;
  margin-right: 1%;
  margin-left: 1%;
  cursor: pointer;
}

.collection-card-count {
  color: white;
  text-align: center;
  margin-top: 0.5vh;
  font-size: 2vh;
  letter-spacing: .1rem;
}

.collection-card:hover {
  transform: scale(1.05);
}

.collection-card.greyed {
  filter: grayscale(1);
  opacity: 0.6;
}


</style>