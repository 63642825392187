<template>
  <div class='show-container' v-click-outside="quit">
    <CollectionCard :style="{boxShadow: card.borderColor + ' 0px 0.75vh 2.25vh'}"
      ref="card"
      :background="card.background"
      :borderColor="card.borderColor"
      :titleColor="card.titleColor"
      :descriptionColor="card.descriptionColor"
      :statColor="card.statColor"
      :numberColor="card.numberColor"
      :title="card.title"
      :icon="card.icon"
      :icon2="card.icon2"
      :image="card.image"
      :imageBorderColor="card.imageBorderColor"
      :rarity="card.rarity"
      :description="card.description"
      :stat1Name="card.stat1Name"
      :stat1Value="card.stat1Value"
      :stat2Name="card.stat2Name"
      :stat2Value="card.stat2Value"
      :number="number"
      :max="Object.keys(collection.cards).length"
    ></CollectionCard>
  </div>
</template>

<script>
import CollectionCard from '@/components/CollectionCard.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    CollectionCard
  },
  computed: {
    ...mapGetters(['collections', 'ownedCards']),
    card () {
      return this.collections[this.collectionKey].cards[this.cardKey]
    },
    collection () {
      return this.collections[this.collectionKey]
    },
    count () {
      if (this.ownedCards[this.collectionKey] ===  undefined) return 0
      return this.ownedCards[this.collectionKey][this.cardKey] || 0
    },
    number () {
      return Object.keys(this.collection.cards).indexOf(this.cardKey) + 1
    }
  },
  name: 'CardSHow',
  props: {
    cardKey: String,
    collectionKey: String,
    inAlbum: Boolean
  },
}
</script>

<style scoped>

.show-container {
    position: fixed;
    z-index: 999;
    top: 50%;
    transform: translate(0%, -50%);
    left: 3%;
    width: 35vh;
    height: auto;
}


</style>
