<template>
  <div ref="card" class="card-container" :style="containerStyle">
    <div class="card-header" :style="heightPercent(8)">
      <div class="card-title" :style="titleStyle">{{ title }}</div>
      <img class="card-icon" :src="icon" >
    </div>
    <div class="card-image">
      <div class="card-image-img" :style="imageStyle">
        <img :src="image" :style="imageImgStyle">
      </div>
      <img class="card-icon2" :src="icon2" v-if="icon2 !== undefined && icon2 !== ''">
      <div class="card-rarity" :style="rarityStyle" :class="[rarity]"></div>
    </div>
    <div class="card-description" :style="descriptionStyle">{{ description }}</div>
    <div class="card-stats" :style="statStyle">
      <div class="card-stat" v-if="stat1Name !== undefined">
        <span>{{ stat1Name }}</span>
        <span>{{ stat1Value }}</span>
      </div>
      <div class="card-stat" v-if="stat2Name !== undefined">
        <span>{{ stat2Name }}</span>
        <span>{{ stat2Value }}</span>
      </div>
    </div>
    <div class="card-number" :style="numberStyle">
      <span>{{ number }}</span>/<span>{{ max }}</span>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'CollectionCard',
  data: function () {
    return {
      containerWidth: 0
    }
  },
  props: {
    title: String,
    icon: String,
    icon2: String,
    image: String,
    rarity: String,
    description: String,
    stat1Name: String,
    stat1Value: String,
    stat2Name: String,
    stat2Value: String,
    titleColor: String,
    descriptionColor: String,
    statColor: String,
    numberColor: String,
    background: String,
    borderColor: String,
    imageBorderColor: String,
    number: Number,
    max: Number
  },
  computed: {
    containerStyle() {
      return {
        border: this.percentOfContainer(2) + 'px' + ' solid ' + (this.borderColor || 'black'),
        background: 'url("' + this.background + '")',
        borderRadius: this.percentOfContainer(4) + 'px'
      }
		},
    titleStyle() {
      return {...this.fontPercent(5.5),... {
        color: this.titleColor || 'white'
      }}
    },
    descriptionStyle() {
      return {...this.heightPercent(14),...this.fontPercent(4),... {
        color: this.descriptionColor || 'white',
        overflow: 'visible'
      }}
    },
    statStyle() {
      return {...this.heightPercent(17),...this.fontPercent(6),...{
        lineHeight: this.percentOfContainer(9) + 'px',
        color: this.statColor || 'white'
      }}
    },
    imageStyle() {
      return {
        border: this.percentOfContainer(1.5) + 'px' + ' solid '+ (this.imageBorderColor || 'white'),
        backgroundColor: (this.imageBorderColor || 'white'),
        borderRadius: this.percentOfContainer(2.5) + 'px'
      }
    },
    imageImgStyle() {
      return {
        borderRadius: this.percentOfContainer(2.5) + 'px'
      }
    },
    rarityStyle() {
      return {
        border: this.percentOfContainer(1) + 'px' + ' solid '+ (this.imageBorderColor || 'white'),
      }
    },
    numberStyle() {
      return {...this.fontPercent(3),... {
        color: this.numberColor || 'white'
      }}
    }
  },
  methods: {
    fontPercent(percent) {
      return {
        fontSize: this.percentOfContainer(percent) + 'px'
      }
    },
    heightPercent(percent) {
      return {
        height: this.percentOfContainer(percent) + 'px'
      }
    },
    percentOfContainer (percent) {
      return Math.floor((this.containerWidth)/100*percent)
    },
    onResize () {
      this.containerWidth = this.$refs.card.clientWidth
    },
  },
  mounted () {
    Vue.nextTick(() => {
      this.containerWidth = this.$refs.card.clientWidth
    })
  },
  created () {
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>


<style scoped>

.card-container {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  user-select: none;
  background-size: cover !important;
  background-position: center !important;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10%;
  margin-right: 5%;
  margin-top: 3%;
}

.card-title {
  font-weight: 500;
}

.card-icon {
  height: 115%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  object-fit: cover;
}

.card-icon2 {
  height: 11%;
  position: absolute;
  bottom: 4%;
  left: 4%;
  object-fit: cover;
}

.card-image{
  position: relative;
  margin: 0 8.5%;
  margin-top: 3%;
}

.card-image-img {
  display: flex;
  justify-content: center;
}

.card-image-img img{
  width: 100%;
  min-width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.card-rarity {
  position: absolute;
  bottom: 1%;
  right: 50%;
  transform: translate(50%,50%);
  border-radius: 50%;
  height: 6%;
  aspect-ratio: 1/1;
  background-color: black;
}

.card-rarity.common {
  background: radial-gradient(circle at 30% 60%, white 5%, white 3%, rgb(102, 102, 102) 60%, white 100%);
}

.card-rarity.uncommon {
  background: radial-gradient(circle at 30% 60%, white 5%, rgb(92, 252, 17) 3%, rgb(0, 105, 18) 60%, rgb(92, 252, 17) 100%);
}

.card-rarity.rare {
  background: radial-gradient(circle at 30% 60%, white 5%, aqua 3%, darkblue 60%, aqua 100%);
}

.card-rarity.epic {
   background: radial-gradient(circle at 30% 60%, white 5%, rgb(235, 146, 253) 3%, rgb(80, 23, 117) 60%, rgb(235, 146, 253) 100%);
}

.card-rarity.legendary {
  background: radial-gradient(circle at 30% 60%, white 5%, rgb(248, 188, 76) 3%, rgb(216, 112, 15) 60%, rgb(248, 188, 76) 100%);
}

.card-description {
  margin: 0% 10%;
  margin-top: 6%;
  overflow: hidden;
}

.card-stats {
  margin: 0% 10%;
  margin-top: 2%;
  margin-bottom: 2%;
  white-space: nowrap;
}

.card-stat {
  display: flex;
  justify-content: space-between;
}

.card-number {
  display: flex;
  justify-content: flex-end;
  margin-right: 2%;
  margin-top: 2%;
  margin-bottom: 1%;
}

</style>
