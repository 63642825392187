<template>
  <div class='maker-container'>
    <div class="maker-left">
      <CollectionCard class="maker-card" :style="{boxShadow: borderColor + ' 0px 0.75vh 2.25vh'}"
        ref="card"
        :background="background"
        :borderColor="borderColor"
        :titleColor="titleColor"
        :descriptionColor="descriptionColor"
        :statColor="statColor"
        :numberColor="numberColor"
        :title="title"
        :icon="icon"
        :icon2="icon2"
        :image="image"
        :imageBorderColor="imageBorderColor"
        :rarity="rarity"
        :description="description"
        :stat1Name="stat1Name"
        :stat1Value="stat1Value"
        :stat2Name="stat2Name"
        :stat2Value="stat2Value"
        :count="count"
        :number="number"
        :max="max"
        :preview="true"
      ></CollectionCard>
    </div>
    <div class="maker-right">
      <div class="maker-right-settings">
        <div class="maker-title">Titre</div>
        <input v-model="title" placeholder="Titre" />
        <div class="maker-title">Icone</div>
        <input v-model="icon" placeholder="Icone" />
        <div class="maker-title">Icone 2 (optionnel)</div>
        <input v-model="icon2" placeholder="Icone 2" />
        <div class="maker-title">Image</div>
        <input v-model="image" placeholder="Image" />
        <div class="maker-title">Arrière plan</div>
        <input v-model="background" placeholder="Arrière plan" />
        <div class="maker-title">Description</div>
        <input v-model="description" placeholder="Description" />
        <div class="maker-title">Statistique 1</div>
        <input v-model="stat1Name" placeholder="Statistique 1" />
        <div class="maker-title">Valeur Statistique 1</div>
        <input v-model="stat1Value" placeholder="Valeur Statistique 1" />
        <div class="maker-title">Statistique 2</div>
        <input v-model="stat2Name" placeholder="Statistique 2" />
        <div class="maker-title">Valeur Statistique 2</div>
        <input v-model="stat2Value" placeholder="Valeur Statistique 2" />

        <div class="maker-colors">
          <div class="maker-color">
            <div class="maker-title">Bordure</div>
            <input type="color" v-model="borderColor" placeholder="Bordure" />
          </div>
          <div class="maker-color">
            <div class="maker-title">Bordure Image</div>
            <input type="color" v-model="imageBorderColor" placeholder="Bordure Image" />
          </div>
        </div>

        <div class="maker-colors">
          <div class="maker-color">
            <div class="maker-title">Titre</div>
            <input type="color" v-model="titleColor" placeholder="Couleur Titre" />
          </div>
          <div class="maker-color">
            <div class="maker-title">Description</div>
            <input type="color" v-model="descriptionColor" placeholder="Couleur Description" />
          </div>
          <div class="maker-color">
            <div class="maker-title">Stats</div>
            <input type="color" v-model="statColor" placeholder="Couleur Description" />
          </div>
          <div class="maker-color">
            <div class="maker-title">Numero</div>
            <input type="color" v-model="numberColor" placeholder="Couleur Description" />
          </div>
        </div>
        <div class="maker-title">Rareté</div>
        <select v-model="rarity">
          <option value="common">Commune</option>
          <option value="uncommon">Peu commune</option>
          <option value="rare">Rare</option>
          <option value="epic">Epic</option>
          <option value="legendary">Légendaire</option>
        </select>
      </div>
      <div class="maker-right-import">
        <div class="maker-title">Import</div>
        <textarea id="story" rows="10" cols="33" v-model="importText"></textarea>
        <div class="button import" @click="importJSON">Import</div>
        <div class="maker-title">Export</div>
        <div class="button export" @click="exportJSON">Export</div>
        <div class="maker-title">Download</div>
        <input v-model="collection" placeholder="Collection" />
        <div class="button download" @click="download">Download</div>
      </div>
    </div>
  </div>
</template>

<script>
import CollectionCard from '@/components/CollectionCard.vue'
import saveAs from 'file-saver'

export default {
  components: {
    CollectionCard
  },
  name: 'CardMaker',
  data: function () {
    return {
      collection: '',
      title: 'Elegy Retro Custom',
      icon: 'https://static.wikia.nocookie.net/gtawiki/images/3/3c/Annis-Logo-GTAO.png',
      iconOverride: '',
      icon2: '',
      icon2Override: '',
      image: 'https://pbs.twimg.com/media/FTnjqDZWQAoKD4-?format=jpg',
      imageOverride: '',
      imageBorderColor: '#6dc7cb',
      background: 'https://w0.peakpx.com/wallpaper/392/535/HD-wallpaper-pastel-sky-background.jpg',
      backgroundOverride: '',
      borderColor: '#003748',
      titleColor: '#003847',
      descriptionColor: '#003847',
      statColor: '#003847',
      numberColor: '#003847',
      rarity: 'rare',
      description: "L'Elegy Retro Custom est un coupé sportif de la marque Annis d'origine japonaise.",
      stat1Name: "Vitesse",
      stat1Value: "62",
      stat2Name: "Accélération",
      stat2Value: "58",
      count: "2",
      number: 12,
      max: 38,
      importText: ''
    }
  },
    methods: {
    getCard (image, icon, icon2, background) {
      return {
        title: this.title,
        icon: icon || this.icon,
        icon2: icon2 || this.icon2,
        image: image || this.image,
        imageBorderColor: this.imageBorderColor,
        background: background || this.background,
        borderColor: this.borderColor,
        titleColor: this.titleColor,
        descriptionColor: this.descriptionColor,
        statColor: this.statColor,
        numberColor: this.numberColor,
        rarity: this.rarity,
        description: this.description,
        stat1Name: this.stat1Name,
        stat1Value: this.stat1Value,
        stat2Name: this.stat2Name,
        stat2Value: this.stat2Value
      }
    },
    exportJSON () {
      this.copyToClipboard(JSON.stringify(this.getCard()))
    },
    importJSON () {
      let card = JSON.parse(this.importText)
      Object.assign(this, card)
    },
    copyToClipboard (text) {
      try {
        let dummy = document.createElement('input')
        document.body.appendChild(dummy)
        dummy.setAttribute('value', text)
        dummy.select()
        document.execCommand('copy')
        document.body.removeChild(dummy)
      } catch (error) {
        console.log(error)
      }
    },
    async convertImage(blob, size, square) {
      return new Promise((resolve, reject) => {
        let img = new Image()
        img.onload = function () {

          let canvas = document.createElement('canvas')
          let ctx = canvas.getContext('2d')

          // Make image a square if too large or square mode
          if (img.width > img.height || (square && img.height > img.width)) {
            let canvas2 = document.createElement('canvas')
            let ctx2 = canvas2.getContext('2d')
            const squareSize = Math.min(img.width, img.height);
            canvas2.width = squareSize
            canvas2.height = squareSize
            const x = (img.width - squareSize) / 2
            const y = (img.height - squareSize) / 2
            ctx2.drawImage(img, x, y, squareSize, squareSize, 0, 0, squareSize, squareSize)
            img = canvas2
          }

          if (img.height > size || img.width > size) {
            let ratio = Math.min(size/img.width, size/img.height)
            canvas.width = Math.floor(img.width * ratio)
            canvas.height = Math.floor(img.height * ratio)
          } else {
            canvas.width = img.width
            canvas.height = img.height
          }

          ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height)

          const data = ctx.getImageData(0, 0, canvas.width, canvas.height).data
          for (let i = 3; i < data.length; i += 4) {
            if (data[i] !== 255) {
              canvas.toBlob(
                (blob) => {
                  resolve({ ext: '.png', blob })
                },
                "image/png",
                0.92,
              )
              return
            }
          }
          canvas.toBlob(
            (blob) => {
              resolve({ ext: '.jpg', blob })
            },
            "image/jpeg",
            0.92,
          )
        }
        img.onerror = function () {
          reject(new Error("Unable to load the image."))
        }
        img.src = URL.createObjectURL(blob)
      })
    },
    async calculateHashFromBlob(blob) {
      const arrayBuffer = await blob.arrayBuffer()
      const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer)
      const hashArray = Array.from(new Uint8Array(hashBuffer))
      const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('')
      return hashHex
    },
    async downloadImage (url, suffix, size, square) {
      const response = await fetch(url)
      let imgBlob = await response.blob()
      const {ext, blob} = await this.convertImage(imgBlob, size, square)
      const filename = (this.collection + '_' + await this.calculateHashFromBlob(blob) + suffix + ext).toLowerCase()
      saveAs(blob, filename)
      return filename
    },
    async download () {
      if (!this.collection || this.collection .length == 0) {
        return
      }
      if (this.image && this.image.length > 0) {
        var image = await this.downloadImage(this.image, '_image', 512, true)
      }
      if (this.icon && this.icon.length > 0) {
        var icon = await this.downloadImage(this.icon, '_icon', 96, true)
      }
      if (this.icon2 && this.icon2.length > 0) {
        var icon2 = await this.downloadImage(this.icon2, '_icon2', 96, true)
      }
      if (this.background && this.background.length > 0) {
        var background = await this.downloadImage(this.background, '_bg', 768)
      }
      var blob = new Blob([JSON.stringify(this.getCard (image, icon, icon2, background))], {type: "text/plain;charset=utf-8"});
      saveAs(blob, (this.collection + '_' + this.title.replace(/\s/g, "_") + ".json").toLowerCase())
    }
  }
}
</script>

<style scoped>

.maker-title {
  color: white;
  font-size: 2vh;
  margin-top: 1.5vh;
}

input, textarea, select {
  font-size: 2vh;
}

.maker-container {
  display: flex;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0.5vh 1.5vh;
}

.maker-left {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
}

.maker-card {
  width: 50vh;
}

.maker-right {
  margin-top: 2vh;
  display: flex;
  width: 50%;
}

.maker-right-settings {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.maker-colors {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.maker-right-import {
  display: flex;
  flex-direction: column;
  margin-left: 5%;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5% 10%;
  background-color: white;
  cursor: pointer;
  border: 2px solid black;
  font-size: 2vh;
}

.button:hover {
  background-color: rgb(172, 172, 172);
}

.import {
  margin-top: 0.5vh;
}


</style>
