<template>
  <div class='view-container' v-click-outside="quit">
    <CollectionCard class="view-card" :style="{boxShadow: card.borderColor + ' 0px 0.75vh 2.25vh'}"
      ref="card"
      :background="card.background"
      :borderColor="card.borderColor"
      :titleColor="card.titleColor"
      :descriptionColor="card.descriptionColor"
      :statColor="card.statColor"
      :numberColor="card.numberColor"
      :title="card.title"
      :icon="card.icon"
      :icon2="card.icon2"
      :image="card.image"
      :imageBorderColor="card.imageBorderColor"
      :rarity="card.rarity"
      :description="card.description"
      :stat1Name="card.stat1Name"
      :stat1Value="card.stat1Value"
      :stat2Name="card.stat2Name"
      :stat2Value="card.stat2Value"
      :number="number"
      :max="Object.keys(collection.cards).length"
    ></CollectionCard>
    <div class="view-buttons" v-if="count > 0 || inAlbum !== true">
      <div class='view-album view-button' @click="addRemoveFromAlbum">
        {{ inAlbum === true ? 'Retirer de l\'album' : 'Ajouter à l\'album'}}
      </div>
      <div class='view-show view-button' @click="showPicture">
        Montrer la carte
      </div>
    </div>
  </div>
</template>

<script>
import CollectionCard from '@/components/CollectionCard.vue'
import store from '../store'
import { mapGetters } from 'vuex'

export default {
  components: {
    CollectionCard
  },
  computed: {
    ...mapGetters(['collections', 'ownedCards']),
    card () {
      return this.collections[this.collectionKey].cards[this.cardKey]
    },
    collection () {
      return this.collections[this.collectionKey]
    },
    count () {
      if (this.ownedCards[this.collectionKey] ===  undefined) return 0
      return this.ownedCards[this.collectionKey][this.cardKey] || 0
    },
    number () {
      return Object.keys(this.collection.cards).indexOf(this.cardKey) + 1
    }
  },
  name: 'CardView',
  store: store,
  props: {
    cardKey: String,
    collectionKey: String,
    inAlbum: Boolean
  },
  methods: {
    onKeyUp (event) {
      if (event.key === 'Escape' ||  event.key === 'Backspace') {
        this.quit()
      }
    },
    quit (addRemove) {
      if (addRemove === true) {
        this.$phoneAPI.addRemoveCardFromAlbum(this.collectionKey, this.cardKey, this.inAlbum)
      }
      this.$phoneAPI.exitCardView()
      this.$emit('cancel')
    },
    showPicture () {
      this.$phoneAPI.showCard(this.collectionKey, this.cardKey)
    },
    addRemoveFromAlbum () {
      this.quit(true)
    }
  },
  created () {
    window.addEventListener('keyup', this.onKeyUp)
  },
  beforeDestroy () {
    window.removeEventListener('keyup', this.onKeyUp)
  }
}
</script>

<style scoped>

.view-container {
    position: fixed;
    z-index: 999;
    top: 50vh;
    left: 50%;
    width: 42vh;
    height: auto;
    transform: translate(-50%, -50%);
}

.view-card {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0.5vh 1.5vh;
}

.view-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 2vh;
}

.view-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4vh;
  width: 20vh;
  border-radius: 1vh;
  font-size: 1.5vh;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0.5vh 1.5vh;
  background-color: #F8F3F0;
  cursor: pointer;
  user-select: none;
}

.view-button:hover {
  background-color: #e6e2df;
}

</style>
